<template>
	
	<BackofficeBase>
		<body class="dash__back">
	<div class="overlay"></div>
	<div class="dashboard__wrapper">
		<div class="dashboard__main">
			<div class="new__worker">
				<div class="breadcrumbs">
					<ul>
						<li><a href="#">ניהול עובדים</a></li>
						<li class="spacer">></li>
						<li class="current" v-if="is_active">עובד פעיל  </li>
						<li class="current" v-if="!is_active">עובד לשעבר  </li>
					</ul>
				</div>
				<div class="new__worker--result">
					<div class="result__main">
						<div class="sub__result">
							<div class="result__name">
								<p>שם העובד</p>
								<span>{{ first_name }} {{last_name}}</span>
							</div>
							<div class="regular__field">
								<p>ת”ז</p>
								<span>{{government_id}}</span>
							</div>
							<div class="regular__field">
								<p>מספר טלפון</p>
								<span>{{ phone }}</span>
							</div>
							<div class="regular__field">
								<p>אימייל</p>
								<span>{{ email }}</span>
							</div>
						</div>
						<div class="sub__result">
							<h6>תנאי העובד</h6>
							<div class="double__result">
								<div class="regular__field">
									<p>תשלום בסיס שעתי</p>
									<span>{{hourly_wage}}₪</span>
								</div>
								<div class="regular__field">
									<p>תשלום נסיעות</p>
									<span>{{travel_expenses}}₪</span>
								</div>
							</div>
						</div>
						<div class="sub__result">
							<h6>ותק</h6>
							<div class="regular__field">
								<span v-if="is_active == 1">עובד פעיל,</span>
								<span v-if="is_active == 0">עובד לא פעיל,</span>
								<span> ותק  {{calculateTimePassed}}</span>
							</div>
							<div class="regular__field">
								<p>תאריך התחלת עבודה</p>
								<span>{{ start_date_to_show }}</span>
							</div>
						</div>
					</div>
					<!-- v-if="is_active" -->
					<div class="result__button" >
                        <!-- /backoffice/edit_emploeey -->
						<router-link :to="{path : '/backoffice/edit_emploeey', query : {'id' : id}}">עריכה</router-link>
					</div>
				</div>
			</div>
		</div>
    <BackofficeSidebar></BackofficeSidebar>
	</div>

	<div class="modal__wrapper" style="display:none;">
		<div class="inner__modal">
			<div class="modal__box export">
				<a href="#"><img src="img/close.svg" alt="close"></a>
				<form action="">
					<h2>הנפקת דו”ח</h2>
					<div class="group__input group__dropdown">
						<p>בחירת חודש</p>
						<a href="#"><span>מאי 2023</span> <img src="img/arrow.svg" alt="arrow"></a>
						<div class="dropdown__box" style="display:none;">
							<ul>
								<li><a href="#">1</a></li>
								<li><a href="#">2</a></li>
								<li><a href="#">3</a></li>
								<li><a href="#">4</a></li>
								<li><a href="#">5</a></li>
								<li><a href="#">1</a></li>
								<li><a href="#">2</a></li>
								<li><a href="#">3</a></li>
								<li><a href="#">4</a></li>
								<li><a href="#">5</a></li>
							</ul>
						</div>
					</div>
					<div class="controls">
						<button type="submit" class="regular-btn">הנפק דו”ח</button>
						<a href="#" class="outline-btn cancel__button">ביטול</a>
						
					</div>
				</form>
			</div>
		</div>
	</div>

</body>
	</BackofficeBase>
</template>
  
  <script>
//   import BackofficeBase from "./BackofficeBase";
  import BackofficeSidebar from "./BackofficeSidebar";
  import BackendModel from "../../models/BackendModel"
  import BackofficeBase from "./BackofficeBase"

  export default {
    name: 'BackofficeEmploee',
    components: {
		BackofficeBase,
		BackofficeSidebar
    },
	data() {
		return {
			data:[],
            first_name:'',
            last_name:'',
            government_id:'',
            phone:'',
            email:'',
            hourly_wage:'',
            travel_expenses:'',
            is_active:0,
            calculateTimePassed:'',
            start_date_to_show:'',
            
		};
	},
	async mounted() {
    this.id = this.$route.query.id    
    let backendModel = new BackendModel()
    let data = await backendModel.backendRequest("/Api/service/backoffice/get_employee",{"id" : this.id})
    // this.data = data.data.data.employee
    this.data = data.data.data.employee[0]
    this.first_name = this.data.first_name
    this.last_name = this.data.last_name
    this.government_id = this.data.government_id
    this.phone = this.data.phone
    this.email = this.data.email
    this.hourly_wage = this.data.hourly_wage
    this.travel_expenses = this.data.travel_expenses
    this.is_active = this.data.is_active
    // if( this.is_active =='1'){
    //     this.is_active =true
    // }else{
    //     this.is_active=false
    // }
    this.calculateTimePassed = this.data.calculateTimePassed
    this.start_date_to_show = this.data.start_date_to_show
	console.log(this.data)
    },
	methods: {

	},
  }
  </script>
  <style >
  @import "../../assets/new_style.css"
  </style>
  
